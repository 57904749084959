const dictionary = {
    sign_in: "Sign in",
    sign_out: "Sign out",
    register: 'Register',
    registration: 'Registration',
    email: 'Email',
    email_small: 'e-email',
    password: 'Password',
    password_again: 'Password again',
    Upload_documents: 'Upload documents',
    Upload_documents_title: 'If you represent a company, please upload your Business License\nIf you are a private user, please upload Personal ID',
    The_file_size_cannot_exceed_5_MB: 'The file size cannot exceed 5 MB',
    forget_password: 'Forgot your password?',
    restore_password: 'Restore password',
    restore: 'Restore',
    Continue: 'Continue',
    Skip: 'Skip',
    Start: 'Start',
    Back: "Back",
    cancel: 'Cancel',
    Close: 'Close',
    send: 'Send',
    save: 'Save',
    SaveWithoutFilter: 'Save without filtering',
    SaveAndFilter: 'Save and filter',
    Filter: 'Filter',
    Choose_filtering_variants: 'Choose filtering variants',
    Apply_settings: 'Apply settings',
    Apply: 'Apply',
    Please_complete_the_verification: "Please complete the verification process to gain full access to Meest China's services and customer loyalty program.",
    your_company: 'Сompany name',
    your_name: 'Your name',
    name: 'Name',
    phone_number: 'Phone number',
    phone: 'Phone',
    wechatID: 'WeChat ID',
    ic_crd_num: 'ID Card number',
    send_to_wh: 'send to warehouse',
    wh_text_1: 'You choose $1 parcels for sending in one dispatch. Please fill courier track numbers and CTNS Q-TY',
    wh: 'Warehouse',
    cnts: 'CNTS Q-TY',
    excel_template_download: 'Excel download form',
    Download_Application_Template_Example: 'Download Application Template Example',
    balance: 'Balance',
    Debt: 'Debt',
    //pages
    new_parcels: 'New parcels',
    waiting_arrival: 'Waiting arrival',
    sent_parcels: 'Sent parcels',
    unidentified_parcels: 'Unidentified parcels',
    promo_codes: 'Promo codes',
    accounting: 'Accounting',
    faq: 'FAQ',
    terms_and_conditions: 'MEEST CHINA TERMS AND CONDITIONS',

    admin: 'Admin',
    docs: 'Documentation',

    add_new: 'Add new',
    Сreate_new_parcel: 'Сreate new parcel',
    This_is_not_my_parcel: 'This is not my parcel',
    how_is_this_section_working: 'How is this section working?',
    how_is_this_section_working_info: 'In this section of cabinet you can see all unidentified parcel which can belongs to you.<br/>1)If you see in this section parcel that don\'t belongs to you you can remove he will click "This is not my parcel".<br/>2)If you see in this section parcel that is your you can create new parcel will click "Create new parcel"',
    edit: 'Edit',
    delete: 'Delete',
    print_sticker: 'Print sticker',
    import: 'Import',
    export: 'Export',
    Detailed_form: 'Detailed form',

    //forms
    dest_country: 'Destination country',
    goods_type: 'Goods type',
    tooltip_goods_type: 'Simple goods - general type goods without batteries, powder，magnetism or liquid; Sensitive goods - goods contain batteries (electronics), contain liquids， powders ，magnetism (cosmetics, cartridges, gel, varnish, food additives, dietary supplements, tea) liquids (exception of dangerous level – inflammable, explosive,toxic，oxidizing，etc); Dangerous goods - pure battery (Li and LI-Ion, oxidizing, Ni-Cd, NI-Mh batteries and elements, power banks in plastic cover or without)',
    tooltip_qty: 'This is total quantity of pieces of different types of goods in the parcel, if you have 3 flashlights and 2 t-shorts - you have to fill 5',
    tooltip_curp: 'CURP (Clave Única de Registro de Población) is a unique identification code used in Mexico for registering citizens and residents, and it is required for customs clearance of parcels.',
    send_by: 'Send by',
    qty: 'Quantity',
    qty_placeholder: 'Quantity items',
    weight_kg: 'Weight, KG',
    weight_kg_placeholder: 'Planned parcel weight',
    USD: 'USD',
    CNY: 'CNY',
    value_usd: 'Value, USD',
    value_usd_placeholder: 'Parcel value in USD',
    hs_code: 'HS Code',
    hs_code_placeholder: 'HS Code',
    sku_code: 'SKU Code',
    sku_code_placeholder: 'SKU Code',
    commodity: 'Commodity',
    Estimated_date_delivery_customs: 'Date of Arrival in Destination Country',
    not_determined: 'not determined',
    commodity_placeholder: 'English name only',
    web_link: 'Website link',
    last_mile_track_number: 'Last mile Track number',
    web_link_placeholder: 'Fill if have, link confirming Goods price can help pass customs more quick',
    ioss_number: 'IOSS VAT number',
    Insurance: 'Insurance',
    tooltip_insurances: 'Insurance cost',
    tooltip_ioss: 'Using in parcel IOSS by Meest China',
    zip: 'Post code',
    zip_placeholder: 'Post code',
    region: 'Region',
    region_placeholder: 'Region',
    city: 'City',
    city_placeholder: 'City',
    street: 'Street',
    street_placeholder: 'Street, building #, apartment #',
    receiver_name: 'Consignee name',
    receiver_name_placeholder: 'John Doe',
    receiver_phone: 'Consignee`s phone',
    receiver_phone_placeholder: 'Mobile phone number',
    phone_already_registered: 'This phone number is already registered',
    phone_checking: 'Phone number in the process of checking...',
    email_checking: 'Email in the process of checking...',
    email_already_registered: 'This e-mail is already registered',
    receiver_email: 'Consignee`s email',
    receiver_email_placeholder: 'E-mail address',
    passport_series: 'Passport series',
    passport_series_placeholder: 'ME',
    passport_number: 'Passport number',
    passport_number_placeholder: '3312487',
    tax_id_number: 'Tax ID number',
    tax_id_number_placeholder: '003939248',
    comments: 'Comments',
    comments_placeholder: 'Here you can write your order number, or any other personal comments',
    Fill_in_the_field: 'Fill in the field',
    Annex_to_the_item: 'Annex to the item:',

    delete_selected_parcels: 'Delete selected parcels ?',
    delete_parcels_text_1: 'These items will be permanently deleted. Are you sure?',

    choose_file: 'Choose file',

    charge_account: 'Charge account',
    sum: 'Sum',

    profile: 'Profile',

    //new parcel
    track_number: 'Track number',
    date_of_creating: 'Date of creating',
    qty_2: 'Qty',
    weight: 'Weight',
    value: 'Value',
    ship: 'Ship',
    to: 'To',
    consignee: 'Consignee',
    address: 'Address',
    sn_goods: 'Sensitive goods',
    telephone: 'Telephone',
    comment: 'Comment',
    simple_goods: 'Simple goods',
    sensitive_goods: 'Sensitive goods',
    pure_buttery: 'Dangerous goods',
    general_goods: 'General goods',
    this_type_has_goods_type_Dangerous: 'This type of item has goods type "Dangerous"',

    parcel_number: 'Parcel number',
    dispatch_date: 'Dispatch date',

    poll_name: 'Poll name',
    active_poll: 'Active poll',
    clear_cach: 'Clear cach',
    clear: 'Clear',
    survey_required: 'This survey is required',
    survey_dated: 'Survey dated',

    list_head_created_request: 'Time and date of request',
    list_head_client_id: 'Client ID',
    list_head_req_method: 'Method',
    list_head_req_uri: 'Method uri',
    list_head_request_rate: 'Speed of request in second',
    average_request_time: 'average request time',
    warning_notification_max_value: 'warning notification max value',
    Value_has_been_changed: 'Value has been changed',
    Value_has_been_changed_info: 'There is a risk in the order value. We have changed it to the system fixed value for you. If you need to change it, please contact customer service.',
    Name_metrics: 'Name of metrics',

    remove_from_shipment: 'Remove from shipment',

    date_sent: 'Date sent',
    wh_short: 'WH',
    freight_cost: 'Freight cost, CNY',

    date_from: 'Date from',
    date_to: 'Date to',

    date: 'Date',
    operation: 'Operation',

    prohibited_sensitive: 'Delivery of sensitive goods is prohibited. Use a different delivery type.',
    prohibited_danger: 'Delivery of danger goods is prohibited. Use a different delivery type.',
    prohibited_battery: 'These goods can`t be sent to {$1} by {$2}',

    alertTextAdditionalCustomDuties: 'The declared value you entered has exceeded the tax free limit amount {$1} USD for {$2}, which faces the risk of being charged for additional custom duties. Do you still wish to send?',
    exceededDutyFreeAmount: 'You have exceeded the duty-free amount {$1} USD',
    IAgree: 'I agree',
    IDontAgree: 'I don`t agree',
    notifyTaxUA: 'Please note that any portion of the value over EUR 150 will be subject to tax.',

    copied: 'copied',

    excludedHsCode: 'The HS Code which you entered on line HS Code is prohibited from entering the country of destination',
    excludedCommodity: 'The commodity which you entered  is prohibited from entering the country of destination',

    zipOnlyPUDO: 'This zip code has only PUDO',
    Country_ISO_not_defined: 'Country ISO not defined',
    Not_defined_ISO: 'Not defined by any country\'s ISO. (Number of packages for zip verification after ISO country verification = 0)',
    Zip_verification_request_failed: 'Zip verification request failed',

    errorIossMessage: 'Due to the implementation of new customs clearance rules in EU since 2021.7.1 please fill in your personal IOSS ID number to create a parcel to {$1}. Parcels with an incorrectly IOSS ID number may be detained by European customs. All data that are provided and filled in for this application are on the Client’s side responsibility.',
    errorProhibitedCountry: 'Delivery to {$1} temporarily unavailable',
    nextProblem: 'Parcel from line {$1} has next problem:',
    ImportResponseModalHeader: 'All parcels have been created except',
    ImportResponseModalHeaderError: 'Parcels have not been created',

    row: 'Row',
    rows: 'Rows',
    message: 'Message',
    successImport: 'Success import',
    success: 'Success',
    warning: 'Warning',
    error: 'Error',

    fileNotFound: 'File not selected. Choose a file.',
    selectFile: 'Select file',

    selectSendBy: '---select send by---',
    selectGoodType: '---select good type---',
    selectCountry: '---select country---',
    selectManager: '---select your manager---',

    validIossNumber_1: 'Each IOSS number consists of 12 characters. It should always start with "IM" followed by 10 digits without spaces, e.g. IM0123456789',
    validLength_1: 'The input value must be {1$} characters',
    validLength_2: 'The value must be ${1$} characters long',
    validLengthWords: 'The value must consist at least of ${1$} words',
    validLengthHsCode: 'Please input correctly 10 digits of HS Code',
    validPassportNumber1$: 'The passport number must contain ${1$} digits',
    validPassportSeries1$: 'The passport series must contain ${1$} characters',
    validName: 'The name must be valid',
    validEmpty: 'The field cannot be empty',
    validCopy: 'The field must be unique',
    validEmail: 'The email invalid',
    validPassword: 'Your password must have at least 5 characters',
    validMatch: "Values don't match",
    passwordMatch: "Password does not match",
    validFloat: 'The value must be only digits',
    validNumber: 'The value must be only digits',
    validMaxValue: 'The value must not exceed ${1$}',
    validMaxValue2: 'The maximum allowable weight up to ${1$}',
    validMinValue: 'The value must not be less than ${1$}',
    validMinWeight: 'The minimum allowable weight from ${1$}',
    validOnlyLetter: 'The value must be only letter',
    validOnlyDigitsLetter: 'The value must be only digits and letter',
    validValueIncorrect: 'The value is incorrect',
    validValueForbidden: 'The value is forbidden',
    validValueChinaTrack: 'Incorrect domestic track number',
    validValueCurp: 'CURP is incorrect and should match the following pattern where A is a letter and 1 digit : AAAA111111AAAAAAXX',
    IncorrectCompanyName: 'Incorrect company name',
    IncorrectYourName: 'Incorrect your name',
    IncorrectEmail: 'Incorrect Email',
    IncorrectPhoneNumber: 'Incorrect phone number',
    IncorrectIDCardNumber: 'Incorrect ID Card number',
    IncorrectWeChatId: 'Incorrect we chat id',
    Incorrect: 'Incorrect',
    DomesticTrackCantMatchTrackNumber: 'Domestic track number can`t match with international Track number',

    NoOptionsAvailable: 'No options available',

    sentResetEmail: 'We sent you instructions on how to create a new password',
    passwordSuccessfullyReset: 'Your password has been successfully reset, please log in again using your new password.',

    notifyNewParcelForm: 'Due to the implementation of new customs clearance rules in EU since 2021.7.1 please fill in your personal IOSS ID number to create a parcel to these countries. Parcels with an incorrectly IOSS ID number may be detained by European customs. All data that are provided and filled in for this application are on the Client’s side responsibility.',

    parcelDetails: 'Parcel details',
    ConsigneeDetails: 'Consignee details',

    sheet: 'sheet',
    tape: 'tape',
    custom: 'custom',
    portrait: 'portrait',
    landscape: 'landscape',
    height: 'height',
    format: 'format',
    width: 'width',
    orientation: 'orientation',
    horizontalGapTitle: 'horizontal gap',
    horizontalGap: 'horizontal-gap',
    verticalGapTitle: 'vertical gap',
    verticalGap: 'vertical-gap',
    bottom: 'bottom',
    left: 'left',
    right: 'right',
    top: 'top',
    media: 'media',
    mediaType: 'Media type',
    dimensions: 'Dimensions',
    margins: 'margins',
    gapSize: 'Gap size',
    view: 'view',
    downloadPDF: 'download PDF',

    nothingSelected: 'Nothing selected',
    nothingFound: 'Nothing found',

    search: 'Search',
    GroupSearch: 'Group search',

    pageNotFound_text_1: 'Oops ! Page Not Found !',
    pageNotFound_text_2: 'Main Page !',
    Want_to_leave_page: 'Want to leave the page?',

    updateProfile: 'Update profile',

    noParcelsWaitingArrival: 'No parcels waiting to arrive',

    printSettings: 'Print Settings',
    printSettingsFor: 'Print settings for 500 stickers',
    Maximum_quantity_stickers_1: 'You can select a maximum of 500 stickers per print run.',
    Maximum_quantity_stickers_2: 'To proceed with printing, please ensure you have chosen no more than 500 stickers.',
    TabSystemSettings: 'System settings',
    TabPollSettings: 'Poll settings',
    TabResponsibleEmails: 'Responsible emails',
    ProfileSettings: 'Profile settings',
    NotificationSettings: 'Notification settings',
    SpeedOfRequest: 'Speed of request',
    SpeedOfRequestList: 'Speed of request list',
    SpeedOfRequestStatistic: 'Speed of request statistic',
    EmailsForWarnings: 'Emails for warnings',
    QueryByDate: 'Query by date',
    UserMetricks: 'User metricks',

    dontHaveAnyParcel: "You don't have any parcels",
    noSentParcels: 'No sent parcels',
    noQuestionTemplates: 'No question templates',

    importFromExcel: 'Upload Application Form',
    exportToExcel: 'Export to Excel',
    Download_Selected_Parcels: 'Download Selected Parcels',

    removeQuestion: 'Remove ?',
    removeFromShipmentQuestion: 'Remove from shipment ?',

    selectAll: 'select all',
    unselectAll: 'unselect all',

    pay: 'pay',
    requestTimeout: 'An error has occurred. Please try again later.',
    PromoCodeOnly: 'Promo code only (Optional)',

    //new 31/05/2022
    tracking: 'Tracking',
    TypeDelivery: 'Type of delivery',
    toDoor: 'TO DOOR',
    PUDO: 'PUDO',
    zip_only_PUDO: 'This zip code has only PUDO',
    noStatus: 'No status',

    //new 14/06/2022
    deniedPostalCode: 'Delivery to the selected region is not available',

    pay_selected: 'Pay',
    select_pay: 'select',
    unselect_pay: 'unselect pay',
    Discount_Amount: 'Discount Amount',

    balance_text: 'We have changed the logic of payment and from now you have the opportunity to pay for the delivery of each parcel separately or for all parcels together.',
    infoIOSS: 'Using in parcel IOSS by Meest China',

    Notification: 'Notification',
    noPaidNotification: 'Our system has detected that you have a debt for $number, parcels to  Ukraine in the amount of $amount $currency, please arrange payment so that there are no delays in sending, thank you for your cooperation',
    lostParcelNotification: 'System has detected unidentified parcels : $trackNumbers which might belong to you,please check in "Unidentified parcels"',
    lostParcelsNotification: 'System has detected unidentified parcels which might belong to you,please check in "Unidentified parcels"',

    lwh: 'Length*width*height',
    lwhTooltip: 'measured in warehouse',
    estimateWeight: 'Estimate weight',
    estimateWeightTooltip: "If volume weight>2*actual weight, we take (volume weight-actual weight)/2+actual weight",
    volumizingWeight: 'Volume Weight',
    volumizingWeightTooltip: 'Length*width*height',

    q1: 'How do you like our KK system? is it convenient for creating order?',
    q2: 'How do you like MEEST delivery performance？',
    q3: 'How do you like MEEST logistic tracking?',
    q4: 'How do you like speed of MEEST handling problem?',
    q5: 'How do you like MEEST CS staff? are they polite?',
    q6: 'How do you like MEEST tecanical support?',
    q7: 'Which part of the world\'s market you would like MEEST to add?',
    q8: 'How did you learn about MEEST? via frineds? website ads?',
    q9: 'We would like to hear your opinions on us',
    Check: 'Check',
    qt1: 'Check 5 if you are very satisfied  and 1 if you are very unsatisfied',
    qt2: 'Check 5 if you are very satisfied  and 1 if you are very unsatisfied',
    qt3: 'Check 5 if you are very satisfied  and 1 if you are very unsatisfied',
    qt4: 'Check 5 if you are very satisfied  and 1 if you are very unsatisfied',
    qt5: 'Check 5 if you are very satisfied  and 1 if you are very unsatisfied',
    qt6: 'Check 5 if you are very satisfied  and 1 if you are very unsatisfied',
    Name_process_cheking: 'Name in the process of checking',
    Phone_process_cheking: 'Phone number in the process of checking',
    Price_process_cheking: 'Price in the process of checking',

    // modals
    GroupSearchMenu: 'Group search menu',
    ListBarcodes: 'List the barcodes with a space',

    //accounting
    write_off: 'Write-off',
    refund: 'Refund',
    claims: 'Claims',
    other_expenses: 'Other expenses',
    compensation: 'Compensation',

    //tracker statuses
    returned: 'Returned',
    write_off_parcel: 'write-off parcel',

    //wh
    wh_address: 'Room 106, Building 1, Huajia Pilot Industrial Park, No. 45, Zhong II Section, Shiguang Road, Panyu District, Guangzhou. / ',
    wh_copy: 'Copy actual warehouse address',
    contact_us: 'Contact us',
    scan_QR: 'Scan the QR code to contact us',
    scan_QR_auth: 'If you have any questions, please contact us',
    Ok: 'Ok',
    Information: 'Information',
    Confirm: 'Confirm',
    ConfirmRecipientChange: 'Confirm Recipient Change',
    RecipientChangeQuestion: 'Are you sure you want to change the recipient information?',
    RecipientChangeUpdateInformation: 'This will update the information for that parcel.',

    // Unidentified parcels
    Domestic_express_tracking: 'Domestic express tracking',
    Date_receiving_in_warehouse: 'Date of receiving in warehouse',

    // Promo codes
    Promo_code_number: 'Promo code number',
    Promo_code_amount: 'Promo code amount',
    Your_referral_promo_code: 'Your referral promo code',
    Activation_day: 'Activation day',
    Validity_Date: 'Validity Date',
    The_country_for_which_it_operates: 'The country for which it operates',
    Referral_Program: '【Referral Program】',
    Invite_1_new_user: ' Invite 1 new user to earn ￥100 promo code, more invitations, more promo codes!',
    promo_code_will_become: 'Your promo code will become active as soon as your invited friend makes his first purchase.',
    Currency: "Currency",

    verification_code: 'Verification code',
    verification_code_incorrect: 'Your verification code is incorrect',
    send_code: 'Send Code',
    Explore_more_news: 'Explore more news',
    feedback_title: 'Tell us what you think, good or bad. We want to hear it all!',
    Submit_Feedback: 'Submit Feedback',
    Feedback: 'Feedback',
    Thank_you_for_feedback: 'Thank you for your feedback! We will review it shortly.',
    register_message: 'To ensure smooth shipping, our specialist will contact you soon to collect a photo of your ID or business license for export documentation.',
    Proceed_to_Meest: 'Proceed to Meest+',
    parcel_is_being_processed: 'The parcel is being processed. Try again in 1 minute.',
    All_countries: 'All countries',
    EU: 'EU',
    All_non_EU: 'All non-EU countries',
    All_countries_hint: '*The promo code is available for countries in Europe and other regions, but there may be restrictions on its use in some countries.',
    New_website: 'Back to website',
    Date_of_birth: 'Date of birth',
    date_of_birth_notification: 'Update your profile to get personalized offers! Add your birthday to enjoy special treats',
    How_do_I_use_the_coupon_code: 'How do I use the coupon code?',
    Using_Your_Promo_Code: `1. Using Your Promo Code
        <br/>To use a promo code, first copy the code from the “Promo Codes” section in your account. Then, paste the code into the payment form when paying for your parcel.
        <br/>2. Promo Code Eligibility
        <br/>Promo codes can only be applied to parcels that were created after the promo code’s creation date. Make sure the parcel was created after the promo code was issued to qualify.`,
}

export default dictionary;