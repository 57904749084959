import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Lottie from 'lottie-react';

const Spinner = () => {
    const [animation, setAnimation] = useState(null);
    const spinnerRaccoon = useSelector(state => state.global.spinnerRaccoon);

    useEffect(() => {
        fetch('/assets/files/chinese_new_year_loader.json')
            .then(response => response.json())
            .then(data => setAnimation(data));
    }, []);

    return (spinnerRaccoon ?
            <div style={{
                width: '500px',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                borderRadius: '50%'
            }}>
                {animation && <Lottie
                    animationData={animation}
                    loop={true}
                    autoplay={true}
                />}
            </div>
            : <div className="lds-dual-ring"/>
    );
};

export default Spinner;
